@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Montserrat-latin-400.90a7a52b.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Montserrat-latin-700.90a7a52b.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("SourceSansPro-latin-400.14d85855.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*# sourceMappingURL=index.2e4ec68e.css.map */
